import React from 'react';
import PageCenter from "../Atoms/PageCenter";
import Stack from "@mui/material/Stack";
import {Typography} from "@mui/material";
import SetPassword from 'Components/SetPassword/SetPassword';

const FormSubmitted = ({text1, text2, text3, editable = false}) => {
  

  return (<PageCenter>
      <Stack spacing={0} sx={{
        maxWidth:'67%',
        padding:'48px 0 180px',
        margin: '40px auto'
      }}>
        <Typography variant="div" component="div"
                    sx={{
                      fontSize: 58,
                      marginBottom:'40px'
                    }}
        >{text1}</Typography>
        <Typography variant="div" component="div" sx={{fontSize: 28}}>{text2}</Typography>
        {text3 && <Typography variant="div" component="div" sx={{
          fontSize: 28,
          marginTop: 3,
          marginBottom:'40px'
        }}>{text3}</Typography>}
        <SetPassword />
      </Stack>
  </PageCenter>)
}

export const FormSubmittedProps = [
  {key: 'text1', type: 'string', default: 'Thank you for your time!', label: 'Heading'},
  {key: 'text2', type: 'string', default: 'We\'ll be in touch soon with a few more music, pillow and tipple questions.', label: 'Text 1'},
  {key: 'text3', type: 'string', default: '', label: 'Text 2 (Optional)'}
]

FormSubmitted.defaultProps = FormSubmittedProps.reduce(
  (acc, cur) => ({...acc, [cur.key]: cur.default})
  , {});

export default FormSubmitted;
