import React, {useContext, useEffect, useState} from 'react';
import FormContainer from "../formBuilding/FormContainer";
import {documentStore} from "../documentStore";
import {getDocument, getUserProperties} from "../KiteDocuments/api";
import {useNavigate, useParams} from "react-router-dom";
import {Form} from "formBuilding";
import {createOrUpdateCustomer, getCurrent, postFromSubmission, submission} from "../Services/api";
import * as yup from "yup";
import FormFooter from "../Molecules/FormFooter";
import {CATEGORY_TAGS} from "../helpers/constants";
import PageHeader from "../Molecules/PageHeader";
import {get, kebabCase} from "lodash";
import {checkVisited, getPageVisitInfo} from "../Services/VisitPage";

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
let firstName = urlParams.get('f');
let lastName = urlParams.get('l');
const group = urlParams.get('gr');
let groupCode = null;
let email = urlParams.get('e');

/**
 * Container Component for the from edit and the actual form
 * @returns {JSX.Element}
 * @constructor
 */
const Kitecamp = ({type='live'}) => {
  let { id } = useParams();
  const navigate = useNavigate();
  const {docState, docDispatch, appDispatch} = useContext(documentStore);
  const [loading, setLoading] = useState(true);
  const [done, setDone] = useState(false);
  const [document, setDocument] = useState({});
  const [guest, setGuest] = useState(null);

  useEffect(() => {
    getDocument(id).then(docRes => {
      let doc = docRes.data[0];
      getCurrent().then(res => {
        const venueId = doc.siteId;
        email = res.data.email;
        firstName = res.data.firstName;
        lastName = res.data.lastName;
        const user = {email: email, firstName: res.data.firstName, lastName: res.data.lastName, account: true};
        appDispatch({type: 'update user', payload: user});
        getUserProperties(venueId, email).then(userRes => {
          const guestProperties = userRes.data.guest[0]?.properties;
          setGuest({...user, properties: guestProperties});
        }, () => {
          setGuest(user);
        });
        setDocument(doc);
      }, (error) => {
        console.log(error);
        setDocument(doc);
        setGuest({});
      });
    });
  }, [id, appDispatch]);

  const applyPreviousValues = (shape, guest) => {
    if (guest.properties) {
      shape.forEach(formElement => {
        if (formElement.category) {
          const normalisedId = `${formElement.category.toLowerCase()}--${kebabCase(formElement.id)}`;
          const value = get(guest.properties, normalisedId);
          if (value) {
            if (formElement.selectValues) {
              formElement.selectValues.forEach(option => {
                if (value.value.indexOf(option.title) > -1) {
                  option.selected = true;
                }
              });
            } else if (formElement.type === 'textField') {
              formElement.value = value.value;
            }
          }
        }
      });
    }
  }

  useEffect(() => {
    if (guest) {
      if (document.document) {
          applyPreviousValues(document.liveDocument.shape, guest);

          // Hack in some email validation and remove first name and last name and email if they are in the url
          document.liveDocument.shape.forEach(formElement => {
            if (formElement.id === 'email') {
              formElement.validation = yup.string().email().required('An email is required');
            } else if (formElement.required) {
              if (formElement.type === 'multiSelect') {
                formElement.validation = yup.array().test("optionSelected", "Please select an option",
                  value => value.filter(option => option.selected).length > 0);
              } else if (formElement.type === 'textField') {
                formElement.validation = yup.string().required('This field is required');
              }
            }
          });

          // Remove firstName and lastName from shape array if it's in the url
          if (firstName && lastName) {
            document.liveDocument.shape = document.liveDocument.shape.filter(formElement => {
              return formElement.id !== 'firstName' && formElement.id !== 'lastName';
            });
          }

            // remove share link if this is a group entry
            if (group) {
              document.liveDocument.shape = document.liveDocument.shape.filter(formElement => {
                return formElement.id !== 'share_link' && formElement.id !== 'shareGroupQuestion';
              });
            } else { // generate a random 10 character string to identify the group
              const randomString = Math.random().toString(36).substring(2, 15);
              groupCode = randomString;
              document.liveDocument.group = randomString;
            }


          if (email) {
            document.liveDocument.shape = document.liveDocument.shape.filter(formElement => {
              return formElement.id !== 'email';
            });
          }

          const title = get(document, 'name', 'No kite name found');
          const eventId = document.eventId || document.campaignId;
          checkVisited(title, eventId, firstName, lastName);

          window.document.title = get(document, 'liveDocument.pageHeader[0].eventTitle', 'Rose Hospitality');

          docDispatch({ type: 'update document', payload: type === 'live' ? document.liveDocument : document.document });

          setLoading(false);
      }
    }
  }, [document, docDispatch, type, guest]);

  const gotoSubmitPage = () => {
    setTimeout(() => {
      setDone(true);
      setTimeout(() => {
        navigate('/submission');
      }, 300);
    }, 400);



  }

  /**
   * Structure our values in to tags and deal with multi answer questions.
   * @param tagName
   * @param tags
   * @param label
   * @param values
   * @param key
   */
  const processTags = (tagName, tags, label, values, key) => {
    if (tags[tagName]) {
      const keyValue = getLabelAndValue(label, values[key], true);
      if (keyValue) {
        tags[tagName].push(keyValue);
      }
    }
  }

  /**
   * Get the label and value for the tags
   * @param label
   * @param value
   * @returns {{label, value: *}}
   */
  const getLabelAndValue = (label, value, join = false) => {
    if (Array.isArray(value)) {
      let filteredValue = value
        .filter((v) => v.selected)
        .map((v) => `${v.title}${v.otherValue ? `, ${v.otherValue}` : ''}`);
      if (join) {
        filteredValue = filteredValue.join(';');
      }
      if (filteredValue !== "" && filteredValue.length > 0) {
        return {label: label, value: filteredValue};
      } else {
        return false;
      }
    } else {
      if (value && value !== "") {
        return {label: label, value: value};
      } else {
        return false;
      }
    }
  }

  /**
   * POST to the new API
   * @param values
   */
  const newAPIProcess = async (state, values) => {
    const properties = {};
    const baseProperties = ['firstName', 'lastName', 'email'];
    Object.keys(values).forEach(key => {
      const matchingField = (state.shape.filter((field) => field.id === key));
      const label = matchingField[0].shortLabel || matchingField[0].propertyName || matchingField[0].label;
      if (label && matchingField[0].category && values[key] && !baseProperties.includes(key)) {
        const keyValue = getLabelAndValue(label, values[key]);
        if (keyValue) {
          properties[`${matchingField[0].category.toLowerCase()}--${kebabCase(key)}`] = keyValue;
        }
      }
    });

    const submissionData = {
      email: email || values.email,
      firstName: firstName || values.firstName,
      lastName: lastName || values.lastName,
      eventId: document.eventId,
      campaignId: document.campaignId,
      formId: document._id,
      venueId: document.siteId,
      properties: properties
    }

    if (groupCode) {
      submissionData.groupCode = groupCode;
    }

    if (group) {
      submissionData.groupCode = group;
    }

    if (properties['misc--opt-in']) {
      submissionData.optIn = properties['misc--opt-in'].value[0] === 'OK';
    }

    return submission(submissionData);
  }

  return (
    <React.Fragment>
      {!loading &&
        <div className={done ? 'page-out' : ''}>
          <FormContainer>
            <PageHeader {...docState.present.pageHeader[0]} />
            {<Form
              shape={docState.present.shape}
              onSubmit={async ({values, setSubmitting, setErrors}) => {
              if ((email || values.email) && type === 'live') {

                // Old API process
                let tags = CATEGORY_TAGS;
                const tempUser = {
                  email: email || values.email,
                  firstName: firstName || values.firstName,
                  lastName: lastName || values.lastName,
                };

                let customer = {
                  ...tempUser,
                  siteId: document.siteId,
                  groups: [document.name],
                  typeOfGuest: document.targetCustomer
                };

                if (document.eventId) {
                  customer.events = [document.eventId];
                }

                if (document.campaignId) {
                  customer.campaigns = [document.campaignId];
                }

                Object.keys(values).forEach(key => {
                  const matchingField = (docState.present.shape.filter((field) => field.id === key));
                  const label = matchingField[0].shortLabel || matchingField[0].propertyName || matchingField[0].label;
                  if (label && matchingField[0].category) {
                    processTags(matchingField[0].category.toLowerCase()+'Tags', tags, label, values, key);
                  }
                });

                createOrUpdateCustomer({...customer, ...tags});

                const pageVisitInfo = getPageVisitInfo();

                // Make a record of all form submissions
                postFromSubmission({
                  siteId: document.siteId,
                  eventId: document.eventId,
                  kiteTitle: get(document, 'name', 'No Title'),
                  campaignId: document.campaignId,
                  firstPageLoad: pageVisitInfo?.createdAt,
                  formId: document._id,
                  typeOfGuest: document.targetCustomer,
                  email: email || values.email,
                  groupCode: groupCode || group,
                  firstName: firstName || values.firstName,
                  lastName: lastName || values.lastName,
                  ...tags
                });

                // Pass to the new API process.
                const guest = await newAPIProcess(docState.present, values);

                appDispatch({type: 'update temp user', payload: {...tempUser, hasAccount: guest?.data?.ha, venueID: document.siteId}});
              }
              setSubmitting(true);
              gotoSubmitPage();
            }}/>}
            <FormFooter />
          </FormContainer>
        </div>
        }
    </React.Fragment>
  );
};

Kitecamp.propTypes = {

};

export default Kitecamp;
