import React, {useContext} from "react";
import {documentStore} from "../documentStore";
import FooterSwoosh from "../Atoms/FooterSwoosh";
import PageCenter from "../Atoms/PageCenter";
import {Box} from "@mui/material";
import DocumentElement from "../formBuilding/DocumentElement";
import RHLogo from "Atoms/RHLogo";

const VenueContactDetails = ({address, copyright, logo, logoLink, logoWidth, editable = false}) => {
  const {docState, appState, dispatch} = useContext(documentStore);
  const backgroundSettings = {};
  if (docState.present.kiteTheme.footerBackground) {
    backgroundSettings.background = docState.present.kiteTheme.footerBackground;
  } else {
    backgroundSettings.backgroundColor = docState.present.kiteTheme.primary.main;
  }

  return (

    <div style={{
      ...backgroundSettings,
      position: 'relative',
      padding: `${docState.present.kiteTheme.noSwoosh ? '100px' : '230px'} 0 0 0`,
    }}>
      {!docState.present.kiteTheme.noSwoosh && <FooterSwoosh background={docState.present.kiteTheme.background.default}/>}
      <PageCenter style={{zIndex:1}}>
        <DocumentElement
          editable={editable}
          isSelected={appState.selected.key === 'contact' && appState.selected.index === 0}
          onSelect={() => {
            dispatch({
              type: 'update selected',
              payload: {
                key: 'footer',
                tab: 0,
                index: 0
              }
            });
          }}
        >
        <Box sx={{
          display: 'flex',
          flexDirection: {xs:'column', md: 'row'},
          textAlign: {xs:'center', md: 'left'},
          padding: '18px 0'
        }}>
          <div>
            <Box sx={{color: 'primary.contrastText'}}>
                <span><a href={logoLink}

                         style={{
                           borderWidth: 0,
                           border: 0
                         }}><img
                  src={logo}
                  style={{width: logoWidth, borderWidth: 0, border: 0}} width={logoWidth} alt="Logo"
                  title="Logo_Rose"/></a></span>
              <p>{address}</p>
              <p>{copyright}</p>
            </Box>
          </div>
          <div style={{flex:'1 0 auto'}}></div>
          <Box sx={{
            display: {xs:'block', md: 'flex'},
            alignItems: 'flex-end',
            color: 'primary.contrastText',
            textAlign: {xs:'center', md: 'right'},
          }}>
            <div>
              <a href={'https://rose-hospitality.com'}>
                <RHLogo fillColor={appState.currentPalette.palette ? appState.currentPalette.palette.primary.contrastText : 'pink'}/>
              </a>
              <p>© Rose Hospitality Limited {new Date().getFullYear()}</p>
            </div>
          </Box>
        </Box>
        </DocumentElement>
      </PageCenter>
    </div>
  );
}

export const VenueContactDetailsProps = [
  {key: 'address', type: 'string', default: '6 Selwyn Gardens, Cambridge, CB3 9AX'},
  {key: 'copyright', type: 'string', default: '© Rose Castle Company Limited 2022'},
  {key: 'logoLink', type: 'string', default: 'https://www.rose-hospitality.com'},
  {key: 'logo', type: 'string', default: '/rh-logo-vector-white.svg', image: true},
  {key: 'logoWidth', type: 'number', default: '260', image: true}
]

VenueContactDetails.defaultProps = VenueContactDetailsProps.reduce(
  (acc, cur) => ({...acc, [cur.key]: cur.default})
, {});

export default VenueContactDetails;
