export const base = `${process.env.REACT_APP_API_SERVER}/api/v1/`;
export const serverlessBase = `${process.env.REACT_APP_API_SERVERLESS}/`;
export let baseConfig = {
  headers: {
    'Content-Type': 'application/json'
  }
};

export function setToken() {
  const token = localStorage.getItem('token');
  if (token) {
    baseConfig = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    };
  }
}

setToken();


fetch = (originalFetch => { // eslint-disable-line no-native-reassign, no-global-assign
  return (...agrs) => {
    const result = originalFetch(...agrs);
    return result
      .then(response => {
        if (response.status > 299) {
          //response.json().then(data=>console.log(data));
          throw response;
        } else {
          return response.json();
        }
      })
      .catch(error => {
        if (error.status === '401' || error.status === 401) {
          console.log(error.status);
          console.log(window.location.pathname);
          // redirect unless 'kitecampaign/' is in the path name 

          if (window.location.pathname.indexOf('kitecampaign') === -1) {
            console.log('redirecting');
            console.log(`${process.env.REACT_APP_LOGIN}?rd=${window.location.origin}/token`);
            // set a redirect location to the current page
            localStorage.setItem('redirect', window.location.href);
            window.location.href = `${process.env.REACT_APP_LOGIN}?rd=${window.location.origin}/token`;
            
          } else {
            throw error;
          }
        } else {
          if (error.json) {
            return error.json().then(json => {
              let errors = ['Server error, please try again'];
              if (json.error && json.error.errors) {
                errors = json.error.errors.map(err => err.message);
              }
              const errorObj = { errorArray: errors };
              throw errorObj;
            });
          } else {
            throw error;
          }
        }
        return { error: true };
      });
  };
})(fetch);

export function postFile(creds) {
  const conf = {
    method: 'POST',
    body: creds
  };
  return fetch(`${base}uploads`, conf);
}

export function createOrUpdateCustomer(obj) {
  const conf = {
    method: 'POST',
    body: JSON.stringify(obj),
    ...baseConfig
  };

  return fetch(`${base}customer/createorupdate`, conf);
}

export function signup(obj) {
  const conf = {
    method: 'POST',
    body: JSON.stringify(obj),
    ...baseConfig
  };

  return fetch(`${base}auth/signup`, conf);
}

export async function submission(obj) {
  const conf = {
    method: 'POST',
    body: JSON.stringify(obj),
    ...baseConfig
  };

  return fetch(`${base}guests/submission`, conf);
}

export function postPageVisit(obj) {
  const conf = {
    method: 'POST',
    body: JSON.stringify(obj),
    ...baseConfig
  };

  return fetch(`${base}pagevisit`, conf);
}


export function postFromSubmission(obj) {
  const conf = {
    method: 'POST',
    body: JSON.stringify(obj),
    ...baseConfig
  };

  return fetch(`${base}formsubmission`, conf);
}

export function getCurrent() {
  return fetch(`${base}users/current`, baseConfig);
}
