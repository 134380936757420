import { Formik } from 'formik';
import React, { useContext, useState } from 'react';
import {Typography} from "@mui/material";
import Stack from '@mui/material/Stack';
import { FormElement } from 'formBuilding';
import Button from '@mui/material/Button';
import { documentStore } from 'documentStore';
import * as yup from "yup";
import { setToken, signup } from 'Services/api';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const SetPassword = () => {
  const {appState} = useContext(documentStore);
  const [done, setDone] = useState(false);
  const [type, setType] = useState('password');

  if ((!appState?.user?.email && appState?.tempUser?.email && !done && !appState?.tempUser?.hasAccount)) {
    return (
        <div style={
            {
                borderTop: '1px solid #ccc',
                marginTop: '30px',
                paddingTop: '30px'
            }
        }>
            <Typography variant="div" component="div" sx={{fontSize: 18, marginBottom:'26px', lineHeight:'24px'}}>Take control of your hotel preferences with the <b>Rose Hospitality App</b></Typography>
            <Typography variant="div" component="div" sx={{fontSize: 18, marginBottom:'32px'}}>Choose a password to get started...</Typography>
            <Formik
                initialValues={{ password: '', confirmPassword: '' }}
                validationSchema={yup.object().shape({
                    password: yup.string().required('Password is required')
                })}
                onSubmit={(values, {setSubmitting, setErrors}) => {
                    signup({
                        email: appState?.tempUser?.email,
                        firstName: appState?.tempUser?.firstName,
                        lastName: appState?.tempUser?.lastName,
                        password: values.password,
                        venueIDs: [appState?.tempUser?.venueID],
                        role: 'guest'
                    }).then((response) => {
                        const tokenParam = response.data.token;
                        localStorage.setItem('token', tokenParam);
                        setToken();
                        setDone(true);
                    }, (error) => {
                        console.log(error);
                        setDone(true);
                        setSubmitting(false);
                        setErrors({api: 'There was an error creating your account'});
                    });
                }}
            >
                {(props) => {
                    const {
                        values,
                        touched,
                        errors,
                        isSubmitting,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue
                      } = props;
                      return (
                    <form autoComplete="off" onSubmit={handleSubmit}>
                        <Stack sx={{width:'100%'}} gap={3.2}>
                            <input hidden={true}  id="email" name="email" value={appState?.tempUser?.email} />
                            <div style={{position: 'relative'}}>
                                <FormElement
                                id="password"
                                label="Password"
                                type={type}
                                values={values}
                                errors={errors}
                                touched={touched}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                setFieldValue={setFieldValue} />
                                <Button
                                type="button"
                                size='small'
                                style={{
                                    position: 'absolute',
                                    right: '22px',
                                    top: '31px',
                                    fontSize: 14,
                                    boxShadow: 'none',
                                    borderRadius: 0,
                                    color: '#1976d2',
                                }}
                                onClick={() => setType(type === 'password' ? 'textField' : 'password')}>{type === 'password' ? 'Show' : 'Hide'}</Button>
                            </div>

                            <Typography variant="div" component="div" sx={{fontSize: 13, marginBottom:'18px', marginTop:'4px', lineHeight:'20px'}}>
                                By creating an account you are agreeing to Rose Hospitality <a href='https://www.rose-hospitality.com/' style={{
                                color: '#529eeb',
                                textDecoration: 'none',
                                fontWeight: 'bold'
                            }}
                            target='_blank'
                            rel="noreferrer"
                            >Terms &amp; Conditions <OpenInNewIcon
                            style={{
                                fontSize: 15,
                                position: 'relative',
                                top: '2px'
                            }}
                             />.</a>
                            We collect and use your personal information in accordance with our <a href='https://www.rose-hospitality.com/' style={{
                                color: '#529eeb',
                                textDecoration: 'none',
                                fontWeight: 'bold'
                            }}
                            target='_blank'
                            rel="noreferrer"
                            >Privacy Policies <OpenInNewIcon style={{
                                fontSize: 15,
                                position: 'relative',
                                top: '2px'
                            }} />.</a></Typography>
                        <div>
                            <Button
                            style={{
                            borderRadius:0,
                            padding:'12px 26px',
                            backgroundColor:'#1976d2',
                            fontSize:16,
                            boxShadow: 'none'}}
                            disabled={isSubmitting}
                            type="submit"
                            size={'small'}
                            variant="contained"
                            ><span>Join</span>
                            </Button>
                        </div>
                        </Stack>
                    </form>
                    )
                }}
            </Formik>
        </div>
      );
  } else if (done) {
    return (
        <div style={{padding: '20px', border: '1px solid #ccc'}}>
            <Typography variant="div" component="div" sx={{fontSize: 28, marginBottom:'6px'}}>Account Created</Typography>
        </div>
    );
  } else {
    // display nothing
    return <div style={
      {
          borderTop: '1px solid #ccc',
          marginTop: '30px',
          paddingTop: '30px'
      }
    }>
    <Typography variant="div" component="div" sx={{fontSize: 18, marginBottom:'26px', lineHeight:'24px'}}>Take control of your hotel preferences by continuing to the <a href='https://rose-hospitality.com'
    style={{
      color: '#529eeb',
      textDecoration: 'none',
      fontWeight: 'bold'
  }}
  target='_blank'
  rel="noreferrer">rose hospitality app <OpenInNewIcon style={{
                                fontSize: 15,
                                position: 'relative',
                                top: '2px'
                            }} /></a></Typography>
    </div>;
  }
};

export default SetPassword;
