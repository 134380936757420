/**
 * React component that takes a token parameter from the query string and saves it to local storage
 */

import { setToken } from "Services/api";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const ProcessToken = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const tokenParam = query.get('token');

  useEffect(() => {
    if (tokenParam) {
      localStorage.setItem('token', tokenParam);
      setToken();
      // get the redirect url from local storage
      const redirect = localStorage.getItem('redirect');
      // remove the redirect url from local storage
      localStorage.removeItem('redirect');
      // do the redirect
      window.location.href = redirect;
    } else {
      setError(true);
    }
    setLoading(false);
  }, [tokenParam]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>There was an error processing your token</div>;
  }
};

export default ProcessToken;