import './App.css';
import React from 'react';
import {
  Routes, Route, BrowserRouter
} from "react-router-dom";
import Editor from "./Editor/Editor";
import {StateProvider} from "./documentStore";
import Kitecamp from "./Kitecamp/Kitecamp";
import {KiteErrorBoundary} from "./ErrorBoundary";
import { Submission } from 'Submission/Submission';
import ProcessToken from 'ProcessToken/ProcessToken';

function App() {

  return (
    <StateProvider>
      <MainApp />
    </StateProvider>
  );
}

function MainApp() {
  return (
        <div className="App">
          <KiteErrorBoundary>
            <BrowserRouter>
              <Routes>
                <Route exact path="/" element={<React.Fragment>
                  <h1>RH KITE BUILDER</h1>
                </React.Fragment>} />
                <Route path={`/preview`} element={<Kitecamp type={'preview'} />} />
                <Route path={`/preview/:id`} element={<Kitecamp type={'preview'} />} />
                <Route path={`/kitecampaign`} element={<Kitecamp />} />
                <Route path={`/kitecampaign/:id`} element={<Kitecamp />} />
                <Route path={`/submission`} element={<Submission />} />
                <Route path={`/submission/:id`} element={<Submission />} />
                <Route path={`/edit`} element={ <Editor />} />
                <Route path={`/edit/:id`} element={ <Editor />} />
                <Route path={`/edit/:id/:tab`} element={ <Editor />} />
                <Route path="/token" element={<ProcessToken />}></Route>
              </Routes>
            </BrowserRouter>
          </KiteErrorBoundary>
        </div>
  );
}

export default App;
