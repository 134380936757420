/**
 * Take the array of possible select values and update the actual list of select values.
 * @param componentConfig
 */
export const updateSelectValues = (componentConfig) => {
  if (componentConfig.type === 'multiSelect') {
    componentConfig.selectValues = componentConfig.possibleValues.filter(value => value.active);
  }
}

export const loginRedirect = () => {
  localStorage.setItem('redirect', window.location.href);
  window.location.href = `${process.env.REACT_APP_LOGIN}?rd=${window.location.origin}/token`;
}