const newFormModel = ({data, shape, validation = false}) => {
  let dataClone = {};
  shape.forEach(element => {
    if (element.type !== 'typography' && element.type !== 'image') {
      let key = element.id;
      if (!validation) {
        dataClone[key] = data ?
          data[key] || element.selectValues :
          element.selectValues || element.default;
        if (element.value) {
          dataClone[key] = element.value;
        }
      } else {
        if (element.validation) {
          dataClone[key] = element.validation;
        }
      }
    }
  })
  return dataClone;
}

export { newFormModel };
