import {base, baseConfig} from "../Services/api";

export function create(obj) {
    const conf = {
        method: 'POST',
        body: JSON.stringify(obj),
        ...baseConfig
    };

    return fetch(`${base}kitedocuments`, conf);
}


export function get() {
    return fetch(`${base}kitedocuments`, baseConfig);
}

export function getQuestions() {
  return fetch(`${base}questions?limit=200`, baseConfig);
}

export function getDocument(id) {
    return fetch(`${base}kitedocuments/${id}`, baseConfig);
}

export function getUserProperties(venueId, email) {
    return fetch(`${base}guests/find?venueId=${venueId}&email=${email}`, baseConfig);
}

export function update(id, obj) {
    const conf = {
        method: 'PUT',
        body: JSON.stringify(obj),
        ...baseConfig
    };
    return fetch(`${base}kitedocuments/${id}`,conf);
}

export function createQuestion(obj) {
    const conf = {
        method: 'POST',
        body: JSON.stringify(obj),
        ...baseConfig
    };

    return fetch(`${base}questions`, conf);
}
