import React, {useContext, useState} from 'react';
import propTypes from 'prop-types';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import * as yup from "yup";
import {Formik} from "formik";
import {newFormModel, FormElement } from "./index";
import Stack from '@mui/material/Stack';
import PageCenter from "../Atoms/PageCenter";
import {documentStore} from "../documentStore";
import DocumentElement from "./DocumentElement";
import ContentElement from "./ContentElement";
import {ConditionalShow} from "../Atoms/ConditionalDisplay/ConditionalDisplay";
import { CircularProgress } from '@mui/material';


const Form = ({
  data,
  shape,
  editable = false,
  title = 'New Form',
  subject = 'item',
  onSubmit,
  setSelected = ()=>{},
  addNew = ()=>{},
  deleteItem = () => {},
  create= false,
  selected = {}
}) => {
  const {dispatch} = useContext(documentStore);

  //useClickOutside(form, close);

  const [submitFailed, setSubmitFailed] = useState(false);

  const defaultStyle = {
    marginBottom:'36px',
    fontSize:'14px'
  }

  return (
      <Formik
        initialValues={create ?
          newFormModel({shape: shape}) :
          newFormModel({shape: shape, data: data})}
          onSubmit={(values, {setSubmitting, setErrors}) => {
            setSubmitting(true);
            setSubmitFailed(false);
            onSubmit({values, setSubmitting, setErrors});
          }}

        validationSchema={yup.object().shape(
          newFormModel({shape:shape, validation: true}))
        }

        validateOnBlur={false}
        validateOnChange={false}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            isValid,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue
          } = props;
          return (
            <form autoComplete="off" onSubmit={handleSubmit} style={{width:'100%'}}>
              {submitFailed ? <Alert severity="error" >Failed to {create ? <span>create</span> : <span>update</span>} {subject}.</Alert> : null}
              {errors.api && errors.api.length && <Alert severity="error"><span>*Server errors, </span>
                {errors.api.map(err => (
                  <span key={err}>{err}</span>)
                )}</Alert>}
              <PageCenter>
              <Stack spacing={4} sx={{
                maxWidth:{xs: '75%', md:'67%'},
                paddingBottom:16,
                margin: '0 auto'
              }}>
                      <Stack
                        sx={{width:'100%'}}>
                      {shape.map((formConfig, index) => (
                        <ConditionalShow key={index} config={formConfig} questions={values}>
                            <>
                              {formConfig.component && formConfig.type !== 'multiSelect' && <ContentElement config={formConfig}/> }
                              {(!formConfig.component || formConfig.type === 'multiSelect') && <DocumentElement
                                editable={editable}
                                isSelected={selected.key === 'shape' && selected.index === index}
                                onSelect={() => {
                                  dispatch({
                                    type: 'update selected',
                                    payload: {
                                      key: 'shape',
                                      tab: 0,
                                      index: index
                                    }
                                  });
                                }}
                                addElement={addNew}
                                deleteElement={deleteItem}
                                key={formConfig.id}
                                index={index}
                                hidden={formConfig.hidden}
                                styleOverride={{...defaultStyle, ...formConfig.style}}
                              >
                                <FormElement
                                  id={formConfig.id}
                                  label={formConfig.label}
                                  type={formConfig.type}
                                  textValue={formConfig.textValue}
                                  url={formConfig.url}
                                  width={formConfig.width}
                                  hidden={formConfig.hidden}
                                  selectValues={formConfig.selectValues}
                                  oneSelectable={formConfig.hasOwnProperty('selectOne') ?
                                    formConfig.selectOne :
                                    !formConfig.multiAnswer}
                                  defaultValue={formConfig.default}
                                  values={values}
                                  touched={touched}
                                  errors={errors}
                                  handleBlur={handleBlur}
                                  handleChange={handleChange}
                                  setFieldValue={setFieldValue}
                                />
                              </DocumentElement>}
                            </>
                        </ConditionalShow>
                            )
                          )}
                    </Stack>
                <div>
                  <div style={{
                    position:'relative',
                  }}>
                    <Button
                    style={{
                      borderRadius:0,
                      padding:'16px 35px',
                      fontSize:18,
                      boxShadow: 'none',
                      transition: 'all 0.3s ease',
                      width: isSubmitting ? '90px': '138px',
                      height:63
                    }}
                    disabled={isSubmitting}
                    type="submit"
                    size={'large'}
                    variant="contained"
                    color={(!isValid) ? "error" : "secondary"}>{isSubmitting ? '' : <span>Submit</span>}
                  </Button>
                  <CircularProgress size={22} style={{
                    display: isSubmitting ? 'block' : 'none',
                    position: 'absolute',
                    top: '50%',
                    left: '45px',
                    marginTop: '-11px',
                    marginLeft: '-11px'
                  }}/>
                  </div>
                  {!isValid && <div style={{marginTop:10}}>
                    <Alert severity="error">Oops! Something went wrong. Please check the form for errors and correct them before submitting.</Alert></div>}
                </div>
              </Stack>
              </PageCenter>
            </form>);
        }}
      </Formik>
  );
};

Form.propTypes = {
  data: propTypes.object,
  shape: propTypes.array,
  title: propTypes.string,
  subject: propTypes.string,
  onsubmit: propTypes.func,
  create: propTypes.bool
};

export default Form;
