import React, { useContext, useEffect } from 'react';
import Fade from '@mui/material/Fade';
import SetPassword from 'Components/SetPassword/SetPassword';
import { Box, Typography, Stack } from '@mui/material';
import { documentStore } from 'documentStore';
import PageCenter from 'Atoms/PageCenter';

export function Submission() {
    const { appDispatch, docDispatch} = useContext(documentStore);

    useEffect (() => {
      setTimeout(() => {
        window.scrollTo(0,0);
      },1);
    },[]);

    useEffect (() => {
        docDispatch({type: 'update kite theme', payload:  {"text": {
            "primary": "#fff"
        },
        "secondary": {
            "main": "#e5bd79"
        }
    }});
        appDispatch({type: 'update temp user', payload: {email:'david.buttar@gmail.com', hasAccount: false, venueID: 'abc123'}});
    },[docDispatch, appDispatch]);

    return (
        <div
            style={{
                backgroundColor: '#f2f2f2',
                height: '100vh',
                overflow: 'scroll'
            }}
            className='page-in-surround'
        >
        {<div style={{
            position: 'fixed',
            background: 'url(/_J3A9042-HDR-sm.png) no-repeat center center fixed',
            backgroundSize: 'cover',
            minHeight: '100vh',
            width: '100%',
            opacity: 0.3,
            zIndex:0
        }}></div>}
           <PageCenter>
        <Box sx={{
            padding: {xs: `40px 0 40px`, sm: `40px 0 40px`, md: `60px 0 60px`, lg: `100px 0 100px`},
            /*background: 'url(/_J3A9042-HDR-sm.png) no-repeat center center fixed',
            backgroundSize: 'cover',
            backgroundBlendMode: 'multiply',
            backgroundColor: 'rgba(0,0,0,0.5)',*/
            zIndex:1,
        }}
        className='page-in'
        >
            <div >
                <Stack sx={{

                    color: '#fff',
                    maxWidth: {xs: `400px`, sm: `400px`, md: `900px`, lg: `1200px`},
                    margin: '0 auto',
                    position: 'relative',
                    top:0
                }}
                  direction={{ xs: 'column', md: 'row' }}
                >
                    <Box
                      sx={
                        {
                          background: 'rgb(44 67 89 / 90%)',
                          padding: { xs: '34px', md: '34px 48px 34px 34px', lg: '34px 96px 34px 34px'},
                          flex: 1,
                        }}
                    >
                    <Typography variant="div" component="div" style={
                        {
                          fontSize: '46px',
                          marginBottom: '20px'
                        }
                        }>
                        Thank you
                    </Typography>

                    <Fade in={true} timeout={1000} style={{ transitionDelay: '250ms' }}>
                        <Typography variant="div" component="div" style={
                        {
                          fontSize: '18px'
                        }
                        }>
                        Your answers will help us make your stay exceptional.
                    </Typography>
                    </Fade>
                    <Fade in={true} timeout={1000} style={{ transitionDelay: '700ms' }}>
                        <div>
                            <SetPassword />
                        </div>
                    </Fade>
                    </Box>
                    <div>
                    <Fade in={true} timeout={1000} style={{ transitionDelay: '0ms' }}>
                        <Box
                          sx={{
                            width: { xs: '100%', md: '300px', lg: '400px'},
                            background: {xs:'rgb(44 67 89 / 90%)', md: 'rgb(220 220 220 / 85%)'},
                            border: {xs:'none', md: '1px solid #aaa'},
                            padding: { xs: '0px 34px 34px 34px', md: '34px 34px 34px 34px', lg: '34px 34px 34px 56px'},
                            height: '100%',
                            color: {xs: '#fff', md: '#333'},
                          }}
                        >
                          <Box
                            sx={{
                              borderTop: '1px solid #ccc',
                              paddingTop: '30px',
                              display: { xs: 'block', md: 'none' }
                            }}
                          ></Box>
                          <Typography variant="div" component="div" sx={
                          {
                            fontSize: '40px',
                            marginBottom: '20px'
                          }
                          }>
                          App Features
                          </Typography>
                          <ul style={{
                            listStyle: 'none',
                            padding: 0,
                            margin: 0,
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'space-between'
                          }}>
                            <li>
                              <h3>My Preferences</h3>
                              <p>Save your answers to auto-fill next time</p>
                            </li>
                            <li>
                              <h3>Up-To-Date</h3>
                              <p>Keep your preferences fresh and current</p>
                            </li>
                            <li>
                              <h3>Travel Log</h3>
                              <p>All preferences submitted to each venue</p>
                            </li>
                            <li>
                              <h3>Introduce Me</h3>
                              <p>By email to my next booking</p>
                            </li>
                          </ul>
                        </Box>
                    </Fade>
                    </div>
                </Stack>
            </div>
        </Box>
        </PageCenter>
        </div>
    );
}
